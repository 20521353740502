
import Vue from "vue"

export default Vue.extend({
  name: "FlagIcon",
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  computed: {
    flagCountryIcon() {
      return `fi fi-${this.code?.toLowerCase()}`
    },
  },
})
